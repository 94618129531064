import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../layout/footer";
import Master from "../layout/master";
import Topbar from "../layout/topbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APP_URL, getdates, BASE_URL } from "../validation/basic";
import axios from "axios";
import Chart from "react-apexcharts";
import Paginationcustom from "../validation/pagination";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import TableSkeleton from "../loader/TableSkeleton";

const Dashboard = () => {
  const [fromdate, setFromdate] = useState(getdates(0));
  // console.log(fromdate);
  const [todate, setTodate] = useState(getdates(0));
  const [employees, setEmployees] = useState("");
  const [selectedemp, setSelectedemp] = useState("");
  const [selectedDept, setSelectedDept] = useState("");
  const [depts, setDepts] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [getRole, setGetRole] = useState("");
  const [employee, setEmployee] = useState([]);
  const [selectedpermission, setSelectedpermission] = useState([]);
  const [devicepermission, setDevicepermission] = useState([]);
  const [checkedpermission, setCheckedpermission] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(1);
  const [permissionlist, setPermissionlist] = useState([]);
  const [accesspermission, setAccesspermission] = useState([]);
  const [menuids, setMenuids] = useState("");
  const [dailyactivity, setDailyactivity] = useState([]);
  const [dailyactivityData, setDailyactivityData] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [departments, setDepartments] = useState([]);
  const [dailyActivityLoading,setDailyActivityLoading] = useState(true);
  const [userDeviceLoading,setUserDeviceLoading] = useState(true);

  // const [todayDataLoading,setTodayDataLoading] = useState(false);
  const [hourlyCallLoading,setHourlyCallLoading] = useState(true);
  const [monthlyCallLoading,setMonthlyCallLoading] = useState(true);  
  const [dataLoading, setDataLoading] = useState({
    todayDataLoading: true,
    yesterdayDataLoading: true,
    weekDataLoading: true,
  });

  const [statusLoading , setStatusLoading] = useState({
    todayStatusLoading : true,
    yesterDayStatusLoading : true,
    weekStatusLoading: true,
  });

  const {todayDataLoading, yesterdayDataLoading, weekDataLoading} = dataLoading;

  const {todayStatusLoading,yesterDayStatusLoading,weekStatusLoading} = statusLoading;

  const [dashboardrecord, setDashboardrecord] = useState({
    total_call_log: 0,
    total_incoming: 0,
    total_outgoing: 0,
    total_missed_call: 0,
    total_not_pickup: 0,
    total_sms: 0,
    total_call_log_duration: "00:00:00",
    total_incoming_call_duration: "00:00:00",
    total_ountgoing_call_duration: "00:00:00",
    total_active_users: 0,
    total_ongoing_call: 0,
    total_nonactive_users: 0,
    isr: 0,
    incomming_acd: 0,
    osr: 0,
    oad: 0,
    callback: 0,
  });

  const [negPosCaller, setNegPosCaller] = useState({
    negative_caller: 0,
    personal_caller: 0,
  });

  const [yesterdaydashboardrecord, setYesterdayDashboardrecord] = useState({
    total_yesterday_call_log: 0,
    total_yesterday_incoming: 0,
    total_yesterday_outgoing: 0,
    total_yesterday_missed_call: 0,
    total_yesterday_not_pickup: 0,
    total_yesterday_sms: 0,
    total_yesterday_call_log_duration: "00:00:00",
    total_yesterday_incoming_call_duration: "00:00:00",
    total_yesterday_ountgoing_call_duration: "00:00:00",
    total_yesterday_active_users: 0,
    total_yesterday_ongoing_call: 0,
    total_yesterday_nonactive_users: 0,
    yesterday_isr: 0,
    yesterday_incomming_acd: 0,
    yesterday_osr: 0,
    yesterday_oad: 0,
    yesterday_callback: 0,
  });

  const [negYesterdayPosCaller, setNegYesterdayPosCaller] = useState({
    yesterday_negative_caller: 0,
    yesterday_personal_caller: 0,
  });

  const [weekdashboardrecord, setWeekDashboardrecord] = useState({
    total_week_call_log: 0,
    total_week_incoming: 0,
    total_week_outgoing: 0,
    total_week_missed_call: 0,
    total_week_not_pickup: 0,
    total_week_sms: 0,
    total_week_call_log_duration: "00:00:00",
    total_week_incoming_call_duration: "00:00:00",
    total_week_ountgoing_call_duration: "00:00:00",
    total_week_active_users: 0,
    total_week_ongoing_call: 0,
    total_week_nonactive_users: 0,
    week_isr: 0,
    week_incomming_acd: 0,
    week_osr: 0,
    week_oad: 0,
    week_callback: 0,
  });

  const [negweekPosCaller, setNegweekPosCaller] = useState({
    week_negative_caller: 0,
    week_personal_caller: 0,
  });

  const {
    total_yesterday_call_log,
    total_yesterday_incoming,
    total_yesterday_outgoing,
    total_yesterday_missed_call,
    total_yesterday_not_pickup,
    total_yesterday_sms,
    total_yesterday_call_log_duration,
    total_yesterday_incoming_call_duration,
    total_yesterday_ountgoing_call_duration,
    total_yesterday_active_users,
    total_yesterday_ongoing_call,
    total_yesterday_nonactive_users,
    yesterday_isr,
    yesterday_incomming_acd,
    yesterday_osr,
    yesterday_oad,
    yesterday_callback,
  } = yesterdaydashboardrecord;
  const { yesterday_negative_caller, yesterday_personal_caller } =
    negYesterdayPosCaller;

  const {
    total_week_call_log,
    total_week_incoming,
    total_week_outgoing,
    total_week_missed_call,
    total_week_not_pickup,
    total_week_sms,
    total_week_call_log_duration,
    total_week_incoming_call_duration,
    total_week_ountgoing_call_duration,
    total_week_active_users,
    total_week_ongoing_call,
    total_week_nonactive_users,
    week_isr,
    week_incomming_acd,
    week_osr,
    week_oad,
    week_callback,
  } = weekdashboardrecord;
  const { week_negative_caller, week_personal_caller } = negweekPosCaller;

  const {
    total_call_log,
    total_incoming,
    total_outgoing,
    total_missed_call,
    total_not_pickup,
    total_sms,
    total_call_log_duration,
    total_incoming_call_duration,
    total_ountgoing_call_duration,
    total_active_users,
    total_ongoing_call,
    total_nonactive_users,
    isr,
    incomming_acd,
    osr,
    oad,
    callback,
  } = dashboardrecord;
  const { negative_caller, personal_caller } = negPosCaller;

  /*-------call duration chart-----------*/

  const [lineChart, setLineChart] = useState({
    hours_chart: [],
    series_chart: [],
  });
  const { hours_chart, series_chart } = lineChart;

  /*-------call log activity chart-----------*/

  const [logChart, setLogChart] = useState({
    log_series: [],
    logs_month: [],
  });
  const { log_series, logs_month } = logChart;

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    loaddashboard();
    loaddevice_permission();
    loadusers(depts);
    setInterval(device_permission_updated, 120000);
    loadDepartment();
    //loadRoleData();
    document.querySelector("body").setAttribute("class", "");
  }, []);
  const data = [];
  employee.forEach((item) => {
    data.push({
      value: item.id,
      label: item.name + "-" + item.user_unique_code,
    });
  });

  const departmentData = [];
  departments.forEach((item) => {
    departmentData.push({ value: item.id, label: item.department_name });
  });

  const RoleData = [];
  roles.forEach((item) => {
    RoleData.push({ value: item.id, label: item.role_name });
  });

  const loaddashboard = async () => {
    // setTodayDataLoading(true);
    // setYesterdayDataLoading(true);
    // setWeekDataLoading(true);
    setDataLoading({
      todayDataLoading: true,
      yesterdayDataLoading: true,
      weekDataLoading: true,
    });
    try {
      const dashboard_collect = await axios.get(
        APP_URL +
          "total-records-count?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&user_id=" +
          `${employees}` +
          "&dept=" +
          `${depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let dashboard_list = JSON.parse(JSON.stringify(dashboard_collect.data));
      // console.log(dashboard_list,"dashboard list");
      if (dashboard_list.status == 1) {
        
        setDashboardrecord({
          ...dashboardrecord,
          total_call_log: dashboard_list?.data?.dashboard_count?.total_call_log,
          total_incoming: dashboard_list?.data?.dashboard_count?.total_incoming,
          total_outgoing: dashboard_list?.data?.dashboard_count?.total_outgoing,
          total_missed_call:
            dashboard_list?.data?.dashboard_count?.total_missed_call,
          total_not_pickup:
            dashboard_list?.data?.dashboard_count?.total_not_pickup,
          total_sms: dashboard_list?.data?.dashboard_count?.total_sms,
          total_call_log_duration:
            dashboard_list?.data?.dashboard_count?.total_call_log_duration,
          total_incoming_call_duration:
            dashboard_list?.data?.dashboard_count?.total_incoming_call_duration,
          total_ountgoing_call_duration:
            dashboard_list?.data?.dashboard_count?.total_outgoing_call_duration,
          total_active_users:
            dashboard_list?.data?.dashboard_count?.total_active_users,
          total_nonactive_users:
            dashboard_list?.data?.dashboard_count?.total_nonactive_users,
          isr: dashboard_list?.data?.dashboard_count?.isr,
          incomming_acd: dashboard_list?.data?.dashboard_count?.incomming_acd,
          osr: dashboard_list?.data?.dashboard_count?.osr,
          oad: dashboard_list?.data?.dashboard_count?.oad,
        });
        // setTodayDataLoading(false);
        setDataLoading((prev)=>({
          ...prev,
          todayDataLoading: false
        }));
       
      }

      const dashboard_yesterday_collect = await axios.get(
        APP_URL +
          "total-yesterday-records-count?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&user_id=" +
          `${employees}` +
          "&dept=" +
          `${depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let dashboard_list_yesterday = JSON.parse(
        JSON.stringify(dashboard_yesterday_collect.data)
      );
      if (dashboard_list_yesterday.status == 1) {
        
        setYesterdayDashboardrecord({
          ...yesterdaydashboardrecord,
          total_yesterday_call_log:
            dashboard_list_yesterday.data.dashboard_count.total_call_log,
          total_yesterday_incoming:
            dashboard_list_yesterday.data.dashboard_count.total_incoming,
          total_yesterday_outgoing:
            dashboard_list_yesterday.data.dashboard_count.total_outgoing,
          total_yesterday_missed_call:
            dashboard_list_yesterday.data.dashboard_count.total_missed_call,
          total_yesterday_not_pickup:
            dashboard_list_yesterday.data.dashboard_count.total_not_pickup,
          total_yesterday_sms:
            dashboard_list_yesterday.data.dashboard_count.total_sms,
          total_yesterday_call_log_duration:
            dashboard_list_yesterday.data.dashboard_count
              .total_call_log_duration,
          total_yesterday_incoming_call_duration:
            dashboard_list_yesterday.data.dashboard_count
              .total_incoming_call_duration,
          total_yesterday_ountgoing_call_duration:
            dashboard_list_yesterday.data.dashboard_count
              .total_outgoing_call_duration,
          total_yesterday_active_users:
            dashboard_list_yesterday.data.dashboard_count.total_active_users,
          total_yesterday_nonactive_users:
            dashboard_list_yesterday.data.dashboard_count.total_nonactive_users,
          yesterday_isr: dashboard_list_yesterday.data.dashboard_count.isr,
          yesterday_incomming_acd:
            dashboard_list_yesterday.data.dashboard_count.incomming_acd,
          yesterday_osr: dashboard_list_yesterday.data.dashboard_count.osr,
          yesterday_oad: dashboard_list_yesterday.data.dashboard_count.oad,
        });
        // setYesterdayDataLoading(false);
        setDataLoading((prev)=>({
          ...prev,
          yesterdayDataLoading: false
        }));
      }

      const dashboard_week_collect = await axios.get(
        APP_URL +
          "total-lastweek-records-count?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&user_id=" +
          `${employees}` +
          "&dept=" +
          `${depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let dashboard_list_week = JSON.parse(
        JSON.stringify(dashboard_week_collect.data)
      );
      if (dashboard_list_week.status == 1) {

        setWeekDashboardrecord({
          ...weekdashboardrecord,
          total_week_call_log:
            dashboard_list_week.data.dashboard_count.total_call_log,
          total_week_incoming:
            dashboard_list_week.data.dashboard_count.total_incoming,
          total_week_outgoing:
            dashboard_list_week.data.dashboard_count.total_outgoing,
          total_week_missed_call:
            dashboard_list_week.data.dashboard_count.total_missed_call,
          total_week_not_pickup:
            dashboard_list_week.data.dashboard_count.total_not_pickup,
          total_week_sms: dashboard_list_week.data.dashboard_count.total_sms,
          total_week_call_log_duration:
            dashboard_list_week.data.dashboard_count.total_call_log_duration,
          total_week_incoming_call_duration:
            dashboard_list_week.data.dashboard_count
              .total_incoming_call_duration,
          total_week_ountgoing_call_duration:
            dashboard_list_week.data.dashboard_count
              .total_outgoing_call_duration,
          total_week_active_users:
            dashboard_list_week.data.dashboard_count.total_active_users,
          total_week_nonactive_users:
            dashboard_list_week.data.dashboard_count.total_nonactive_users,
          week_isr: dashboard_list_week.data.dashboard_count.isr,
          week_incomming_acd:
            dashboard_list_week.data.dashboard_count.incomming_acd,
          week_osr: dashboard_list_week.data.dashboard_count.osr,
          week_oad: dashboard_list_week.data.dashboard_count.oad,
        });
        // setWeekDataLoading(false);
        setDataLoading((prev)=>({
          ...prev,
          weekDataLoading: false
        }));
      }

      loadcallerstatus();
      loadhourlycallchart();
      loaduser_Devices();
      loadMonthlycallchart();
      dailyactivity1(1);
    } catch (e) {
      // setTodayDataLoading(false);
      // setYesterdayDataLoading(false);
      // setWeekDataLoading(false);
      setDataLoading({
        todayDataLoading: false,
        yesterdayDataLoading: false,
        weekDataLoading: false,
      });
      return null;
    }
  };

  /*----------load department------------*/

  const loadDepartment = async () => {
    const department_details = await axios.get(APP_URL + "departments", {
      headers: { Authorization: `Bearer ${token}` },
    });
    var js_data_department = JSON.parse(
      JSON.stringify(department_details.data)
    );
    if (js_data_department.status == 1) {
      setDepartments(js_data_department.data.departments);
    }
  };

  /*----load caller status----*/
  const loadcallerstatus = async () => {
    setStatusLoading({
      todayStatusLoading : true,
      yesterDayStatusLoading : true,
      weekStatusLoading : true,
    });
    try {
      const caller_status = await axios.get(
        APP_URL +
          "caller-status?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&user_id=" +
          `${employees}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let dashboard_list = JSON.parse(JSON.stringify(caller_status.data));
      if (dashboard_list.status == 1) {
        setNegPosCaller({
          ...negPosCaller,
          negative_caller: dashboard_list.data.negative_caller,
          personal_caller: dashboard_list.data.personal_caller,
        });
        setStatusLoading((prev)=>({
          ...prev,
          todayStatusLoading : false
        }));
      }

      /*--------yesterday caller status--------*/

      const yesterday_caller_status = await axios.get(
        APP_URL +
          "yesterday-caller-status?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&user_id=" +
          `${employees}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let dashboard_yesterday_list = JSON.parse(
        JSON.stringify(yesterday_caller_status.data)
      );
      if (dashboard_yesterday_list.status == 1) {
        setNegYesterdayPosCaller({
          ...negYesterdayPosCaller,
          yesterday_negative_caller:
            dashboard_yesterday_list.data.negative_caller,
          yesterday_personal_caller:
            dashboard_yesterday_list.data.personal_caller,
        });
        setStatusLoading((prev)=>({
          ...prev,
          yesterDayStatusLoading : false
        }));
      }

      /*--------last week caller status-------*/

      const week_caller_status = await axios.get(
        APP_URL +
          "last-week-caller-status?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&user_id=" +
          `${employees}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let dashboard_week_list = JSON.parse(
        JSON.stringify(week_caller_status.data)
      );
      if (dashboard_week_list.status == 1) {
        setNegweekPosCaller({
          ...negweekPosCaller,
          week_negative_caller: dashboard_week_list.data.negative_caller,
          week_personal_caller: dashboard_week_list.data.personal_caller,
        });
        setStatusLoading((prev)=>({
          ...prev,
          weekStatusLoading : false
        }));
      }
    } catch (e) {
      setStatusLoading({
        todayStatusLoading : false,
        yesterDayStatusLoading : false,
        weekStatusLoading : false
      });
      return null;
    }
  };

  /*------hourly call duration chart----------*/
  const loadhourlycallchart = async () => {
    setHourlyCallLoading(true);
    try {
      const hourly_call_chart = await axios.get(
        APP_URL +
          "hourly-call-chart?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&user_id=" +
          `${employees}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let hourlycall = JSON.parse(JSON.stringify(hourly_call_chart.data));
      if (hourlycall.status == 1) {
        setLineChart({
          ...lineChart,
          hours_chart: hourlycall.data.duration_count.hourse,
          series_chart: hourlycall.data.duration_count.series,
        });
        setHourlyCallLoading(false);
      }
    } catch (e) {
      setHourlyCallLoading(false);
      return null;
    }
  };

  /*---------monthly call log chart---------*/

  const loadMonthlycallchart = async () => {
    setMonthlyCallLoading(true);
    try {
      const monthly_call_chart = await axios.get(
        APP_URL +
          "monthly-call-activity?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&user_id=" +
          `${employees}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let monthlycall = JSON.parse(JSON.stringify(monthly_call_chart.data));
      if (monthlycall.status == 1) {
        setLogChart({
          ...logChart,
          log_series: monthlycall.data.duration_count.series,
          logs_month: monthlycall.data.duration_count.months,
        });
        setMonthlyCallLoading(false);
      }
    } catch (e) {
      setMonthlyCallLoading(false);
      return null;
    }
  };

  const total_call_duration_in_hrs = (call_duration) => {
    let initial_duration = call_duration.split(":");
    var minuts = parseInt(initial_duration[1]);
    var seconds = parseInt(initial_duration[2]);
    var hours = parseInt(initial_duration[0]);
    var total_seconds = +hours * 60 * 60 + +minuts * 60 + +seconds;
    return total_seconds;
  };
  const loaddevice_permission = async () => {
    try {
      const loadpermission = await axios.get(
        APP_URL + "get-device-permission",
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let permission_response = JSON.parse(JSON.stringify(loadpermission.data));
      if (permission_response.status == 1) {
        setDevicepermission(permission_response.data.DevicePermission);
      }
    } catch (e) {
      return false;
    }
  };

  const loadusers = async (Depts) => {
    try {
      const users = await axios.get(
        APP_URL + "user-filter-crm?department=" + `${Depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let users_list_data = JSON.parse(JSON.stringify(users.data));
      setEmployee(users_list_data.data.user_list);
    } catch (e) {
      return null;
    }
  };

  const loaduser_Devices = async () => {
    setUserDeviceLoading(true);
    try {
      const loaduserdevice = await axios.get(
        APP_URL + "user-devices?user_id=" + `${employees}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const loaduserdevices_response = JSON.parse(
        JSON.stringify(loaduserdevice.data)
      );
      if (loaduserdevices_response.status == 1) {
        setSelectedpermission(loaduserdevices_response.data.user_device);
        setUserDeviceLoading(false);
      }
    } catch (e) {
      setUserDeviceLoading(false);
      return false;
    }
  };

  /*-----load role-------*/

  const loadRoleData = async () => {
    try {
      const loaduserdevice = await axios.get(APP_URL + "get-active-role", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const loaduserdevices_response = JSON.parse(
        JSON.stringify(loaduserdevice.data)
      );
      if (loaduserdevices_response.status == 1) {
        setRoles(loaduserdevices_response.data.roles);
      }
    } catch (e) {
      return false;
    }
  };
  const handleemployee = (e) => {
    setSelectedemp(e);
    setEmployees(e.value);
  };

  const handledepartment = (e) => {
    setSelectedDept(e);
    setDepts(e.value);
    loadusers(e.value);
  };

  const handleRole = (e) => {
    setSelectedRole(e);
    setGetRole(e.value);
  };
  const handlefromdate = (e) => {
    setFromdate(e.target.value);
  };
  const handletodate = (e) => {
    setTodate(e.target.value);
  };


  /*--pagination start here---*/

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = selectedpermission.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const nPages = Math.ceil(selectedpermission.length / recordsPerPage);
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const changepage = (e, p) => {
    setCurrentPage(p);
  };

  /*--pagination start end here---*/

  /*------activity pagination start here----*/

  const [activitypage, setActivitypage] = useState(
    dailyactivityData != null ? dailyactivityData.page : 1
  );
  const currentRecords1 = dailyactivity;
  const nPages1 =
    dailyactivityData != null ? dailyactivityData.total_page_count : null;

  const changepage1 = (e, p) => {
    setActivitypage(p);
    dailyactivity1(p);
  };

  /*------activity pagination end here----*/

  /*----check device permission throgh crm----*/

  const device_permission_updated = async () => {
    try {
      const check_permission = await axios.get(
        APP_URL + "permission-updated-crm",
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const check_permission1 = JSON.parse(
        JSON.stringify(check_permission.data)
      );
      if (check_permission1.status == 1) {
        check_permission1.data.permissiondata.forEach((item) => {
          toast.error(
            item.user_info.name +
              "employee code " +
              item.user_info.user_unique_code +
              " Device permission off"
          );
        });
      }
    } catch (e) {
      return false;
    }
  };

  /* ---- daily activity-----*/

  const dailyactivity1 = async (page) => {
    setDailyActivityLoading(true);
    try {
      const activity = await axios.get(
        APP_URL +
          "get-daily-activity?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&user_id=" +
          `${employees}` +
          "&page=" +
          `${page}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const activity_data = JSON.parse(JSON.stringify(activity.data));
      if (activity_data.status == 1) {
        setDailyactivity(activity_data.data.dailyactivity);
        setDailyactivityData(activity_data.data);
        setDailyActivityLoading(false);
      }
    } catch (e) {
      setDailyActivityLoading(false);
      toast.error("something went wrong");
    }
  };

  return (
    <div id="app">
      <div id="main" className="layout-horizontal">
        <Topbar
          setMenuids={setMenuids}
          menuids={menuids}
          setAccesspermission={setAccesspermission}
          accesspermission={accesspermission}
          setPermissionlist={setPermissionlist}
          permissionlist={permissionlist}
        />
        <ToastContainer />
        <div className="content-wrapper container">
          <div className="page-heading">
            <div className="d-flex justify-content-between">
              <div className="">
                <h3>Dashboard</h3>
              </div>
              <div className="">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <td style={{ width: "250px" }}>
                        <Select
                          value={selectedDept}
                          onChange={(e) => handledepartment(e)}
                          options={departmentData}
                        />
                      </td>
                      <td style={{ width: "250px" }}>
                        <Select
                          value={selectedemp}
                          onChange={(e) => handleemployee(e)}
                          options={data}
                        />
                      </td>
                      {/* <td>
                            <input type="date" className="form-control" value={fromdate} onChange={(e)=>handlefromdate(e)}/>
                        </td>
                        <td>
                          <input type="date" className="form-control" value={todate} onChange={(e)=>handletodate(e)} />
                        </td> */}
                      <td>
                        <button
                          className="btn btn-warning"
                          onClick={loaddashboard}
                        >
                          Filter
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="page-content">
            <section className="row">
              <div className="col-4 col-lg-4">
                <div className="card p-3">
                  <h5 className="custom_heading_dashboard">Today</h5>
                  <div className="row">
                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?user=" +
                          employees +
                          "&department=" +
                          depts
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2 text-left">
                                  { todayDataLoading ? <Skeleton width={85}/> : total_call_log}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="iconly-boldCall"></i> Total
                                  Calls
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?user=" +
                          employees +
                          "&department=" +
                          depts
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {todayDataLoading ? <Skeleton width={85}/> : total_call_log_duration}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-clock-o"></i> Call
                                  Duration
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=1&user=" +
                          employees +
                          "&department=" +
                          depts
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {todayDataLoading ? <Skeleton width={85}/> : total_incoming}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-volume-control-phone"></i>{" "}
                                  Incoming Call
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=1&user=" +
                          employees +
                          "&department=" +
                          depts
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {todayDataLoading ? <Skeleton width={85}/> : total_incoming_call_duration}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-clock-o"></i> Incoming
                                  Duration
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=2&user=" +
                          employees +
                          "&department=" +
                          depts
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {todayDataLoading ? <Skeleton width={85}/> : total_outgoing}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-volume-control-phone icon-rotation"></i>{" "}
                                  Outgoing Connected Call
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=2&user=" +
                          employees +
                          "&department=" +
                          depts
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {todayDataLoading ? <Skeleton width={85}/> : total_ountgoing_call_duration}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-clock-o"></i> Outgoing
                                  Duration
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=3&user=" +
                          employees +
                          "&department=" +
                          depts
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {todayDataLoading ? <Skeleton width={85}/> : total_missed_call}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="iconly-boldCall-Missed"></i>{" "}
                                  Missed Call
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=2&callstatus=2&user=" +
                          employees +
                          "&department=" +
                          depts
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {todayDataLoading ? <Skeleton width={85}/> : total_not_pickup}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  {" "}
                                  <i className="iconly-boldCall-Silent"></i> Not
                                  Responding Call
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?listtype=2&user=" +
                          employees +
                          "&department=" +
                          depts
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {todayDataLoading ? <Skeleton width={85}/> : total_sms}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-comment-o"></i> Total SMS
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-2">0</h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-user"></i> New Customer
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12 ">
                              <h6 className="font-extrabold mb-2">0</h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> Working Hours
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-2">
                                {todayDataLoading ? <Skeleton width={85}/> : total_active_users}
                              </h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> Total Active
                                Users
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-2">
                                {todayDataLoading ? <Skeleton width={85}/> : total_nonactive_users}
                              </h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> Total
                                Non-active Users
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-2">{todayDataLoading ? <Skeleton width={85}/> : isr} %</h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> ISR
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-0">
                                {todayDataLoading ? <Skeleton width={85}/> : incomming_acd} Min
                              </h6>
                              <h6 className="text-muted font-semibold">
                                {" "}
                                <i className="fa fa-clock-o"></i> Incomming ACD
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-2">{todayDataLoading ? <Skeleton width={85}/> : osr} %</h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> OSR
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-0">{todayDataLoading ? <Skeleton width={85}/> : oad} Min</h6>
                              <h6 className="text-muted font-semibold">
                                {" "}
                                <i className="fa fa-clock-o"></i> OAD
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12 ">
                              <h6 className="font-extrabold mb-0">
                                {todayDataLoading ? <Skeleton width={85}/> : callback}
                              </h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> Total CallBack
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-0">
                                {todayStatusLoading ? <Skeleton width={85}/> : negative_caller}
                              </h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> Negative
                                Caller(in Month)
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-0">
                                {todayStatusLoading ? <Skeleton width={85}/> : personal_caller}
                              </h6>
                              <h6 className="text-muted font-semibold">
                                {" "}
                                <i className="fa fa-clock-o"></i> Personal
                                Caller(in Month)
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 col-lg-4">
                <div className="card p-3">
                  <h5 className="custom_heading_dashboard">Yesterday</h5>
                  <div className="row">
                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?user=" +
                          employees +
                          "&department=" +
                          depts + "&from_date=" + getdates(1) + "&to_date=" + getdates(1)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2 text-left">
                                  {yesterdayDataLoading ? <Skeleton width={85}/> : total_yesterday_call_log}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="iconly-boldCall"></i> Total
                                  Calls
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?user=" +
                          employees +
                          "&department=" +
                          depts + "&from_date=" + getdates(1) + "&to_date=" + getdates(1)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {yesterdayDataLoading ? <Skeleton width={85}/> : total_yesterday_call_log_duration}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-clock-o"></i> Call
                                  Duration
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=1&user=" +
                          employees +
                          "&department=" +
                          depts + "&from_date=" + getdates(1) + "&to_date=" + getdates(1)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {yesterdayDataLoading ? <Skeleton width={85}/> : total_yesterday_incoming}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-volume-control-phone"></i>{" "}
                                  Incoming Call
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=1&user=" +
                          employees +
                          "&department=" +
                          depts + "&from_date=" + getdates(1) + "&to_date=" + getdates(1)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {yesterdayDataLoading ? <Skeleton width={85}/> : total_yesterday_incoming_call_duration}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-clock-o"></i> Incoming
                                  Duration
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=2&user=" +
                          employees +
                          "&department=" +
                          depts + "&from_date=" + getdates(1) + "&to_date=" + getdates(1)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {yesterdayDataLoading ? <Skeleton width={85}/> : total_yesterday_outgoing}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-volume-control-phone icon-rotation"></i>{" "}
                                  Outgoing Connected Call
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=2&user=" +
                          employees +
                          "&department=" +
                          depts + "&from_date=" + getdates(1) + "&to_date=" + getdates(1)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {yesterdayDataLoading ? <Skeleton width={85}/> : total_yesterday_ountgoing_call_duration}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-clock-o"></i> Outgoing
                                  Duration
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=3&user=" +
                          employees +
                          "&department=" +
                          depts + "&from_date=" + getdates(1) + "&to_date=" + getdates(1)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {yesterdayDataLoading ? <Skeleton width={85}/> : total_yesterday_missed_call}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="iconly-boldCall-Missed"></i>{" "}
                                  Missed Call
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=2&callstatus=2&user=" +
                          employees +
                          "&department=" +
                          depts + "&from_date=" + getdates(1) + "&to_date=" + getdates(1)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {yesterdayDataLoading ? <Skeleton width={85}/> : total_yesterday_not_pickup}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  {" "}
                                  <i className="iconly-boldCall-Silent"></i> Not
                                  Responding Call
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?listtype=2&user=" +
                          employees +
                          "&department=" +
                          depts + "&from_date=" + getdates(1) + "&to_date=" + getdates(1)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {yesterdayDataLoading ? <Skeleton width={85}/> : total_yesterday_sms}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-comment-o"></i> Total SMS
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-2">0</h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-user"></i> New Customer
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12 ">
                              <h6 className="font-extrabold mb-2">0</h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> Working Hours
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-2">
                                {yesterdayDataLoading ? <Skeleton width={85}/> : total_yesterday_active_users}
                              </h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> Total Active
                                Users
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-2">
                                {yesterdayDataLoading ? <Skeleton width={85}/> : total_yesterday_nonactive_users}
                              </h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> Total
                                Non-active Users
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-2">
                                {yesterdayDataLoading ? <Skeleton width={85}/> : yesterday_isr} %
                              </h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> ISR
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-0">
                                {yesterdayDataLoading ? <Skeleton width={85}/> : yesterday_incomming_acd} Min
                              </h6>
                              <h6 className="text-muted font-semibold">
                                {" "}
                                <i className="fa fa-clock-o"></i> Incomming ACD
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-2">
                                {yesterdayDataLoading ? <Skeleton width={85}/> : yesterday_osr} %
                              </h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> OSR
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-0">
                                {yesterdayDataLoading ? <Skeleton width={85}/> : yesterday_oad} Min
                              </h6>
                              <h6 className="text-muted font-semibold">
                                {" "}
                                <i className="fa fa-clock-o"></i> OAD
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12 ">
                              <h6 className="font-extrabold mb-0">
                                {yesterdayDataLoading ? <Skeleton width={85}/> : yesterday_callback}
                              </h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> Total CallBack
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-0">
                                {yesterDayStatusLoading ? <Skeleton width={85}/> : yesterday_negative_caller}
                              </h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> Negative
                                Caller(in Month)
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-0">
                                {yesterDayStatusLoading ? <Skeleton width={85}/> : yesterday_personal_caller}
                              </h6>
                              <h6 className="text-muted font-semibold">
                                {" "}
                                <i className="fa fa-clock-o"></i> Personal
                                Caller(in Month)
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-4 col-lg-4">
                <div className="card p-3">
                  <h5 className="custom_heading_dashboard">Last Week</h5>
                  <div className="row">
                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?user=" +
                          employees +
                          "&department=" +
                          depts + "&from_date=" + getdates(7)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2 text-left">
                                  {weekDataLoading ? <Skeleton width={85}/> : total_week_call_log}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="iconly-boldCall"></i> Total
                                  Calls
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?user=" +
                          employees +
                          "&department=" +
                          depts + "&from_date=" + getdates(7)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {weekDataLoading ? <Skeleton width={85}/> : total_week_call_log_duration}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-clock-o"></i> Call
                                  Duration
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=1&user=" +
                          employees +
                          "&department=" +
                          depts + "&from_date=" + getdates(7)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {weekDataLoading ? <Skeleton width={85}/> : total_week_incoming}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-volume-control-phone"></i>{" "}
                                  Incoming Call
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=1&user=" +
                          employees +
                          "&department=" +
                          depts + "&from_date=" + getdates(7)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {weekDataLoading ? <Skeleton width={85}/> : total_week_incoming_call_duration}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-clock-o"></i> Incoming
                                  Duration
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=2&user=" +
                          employees +
                          "&department=" +
                          depts + "&from_date=" + getdates(7)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {weekDataLoading ? <Skeleton width={85}/> : total_week_outgoing}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-volume-control-phone icon-rotation"></i>{" "}
                                  Outgoing Connected Call
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=2&user=" +
                          employees +
                          "&department=" +
                          depts + "from_date=" + getdates(7)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {weekDataLoading ? <Skeleton width={85}/> : total_week_ountgoing_call_duration}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-clock-o"></i> Outgoing
                                  Duration
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=3&user=" +
                          employees +
                          "&department=" +
                          depts + "&from_date=" + getdates(7)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {weekDataLoading ? <Skeleton width={85}/> : total_week_missed_call}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="iconly-boldCall-Missed"></i>{" "}
                                  Missed Call
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?type=2&callstatus=2&user=" +
                          employees +
                          "&department=" +
                          depts + "&from_date=" + getdates(7)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {weekDataLoading ? <Skeleton width={85}/> : total_week_not_pickup}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  {" "}
                                  <i className="iconly-boldCall-Silent"></i> Not
                                  Responding Call
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <Link
                        to={
                          "/master/manage-call-recording?listtype=2&user=" +
                          employees +
                          "&department=" +
                          depts + "&from_date=" + getdates(7)
                        }
                      >
                        <div className="card bg-light">
                          <div
                            className="card-body"
                            style={{ padding: "10px" }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-extrabold mb-2">
                                  {weekDataLoading ? <Skeleton width={85}/> : total_week_sms}
                                </h6>
                                <h6 className="text-muted font-semibold">
                                  <i className="fa fa-comment-o"></i> Total SMS
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-2">0</h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-user"></i> New Customer
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12 ">
                              <h6 className="font-extrabold mb-2">0</h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> Working Hours
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-2">
                                {weekDataLoading ? <Skeleton width={85}/> : total_week_active_users}
                              </h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> Total Active
                                Users
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-2">
                                {weekDataLoading ? <Skeleton width={85}/> : total_week_nonactive_users}
                              </h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> Total
                                Non-active Users
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-2">
                                {week_isr} %
                              </h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> ISR
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-0">
                                {weekDataLoading ? <Skeleton width={85}/> : week_incomming_acd} Min
                              </h6>
                              <h6 className="text-muted font-semibold">
                                {" "}
                                <i className="fa fa-clock-o"></i> Incomming ACD
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-2">
                                {weekDataLoading ? <Skeleton width={85}/> : week_osr} %
                              </h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> OSR
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-0">
                                {weekDataLoading ? <Skeleton width={85}/> : week_oad} Min
                              </h6>
                              <h6 className="text-muted font-semibold">
                                {" "}
                                <i className="fa fa-clock-o"></i> OAD
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12 ">
                              <h6 className="font-extrabold mb-0">
                                {weekDataLoading ? <Skeleton width={85}/> : week_callback}
                              </h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> Total CallBack
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-0">
                                {weekStatusLoading ? <Skeleton width={85}/> : week_negative_caller}
                              </h6>
                              <h6 className="text-muted font-semibold">
                                <i className="fa fa-clock-o"></i> Negative
                                Caller(in Month)
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-md-6">
                      <div className="card bg-light">
                        <div className="card-body" style={{ padding: "10px" }}>
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="font-extrabold mb-0">
                                {weekStatusLoading ? <Skeleton width={85}/> : week_personal_caller}
                              </h6>
                              <h6 className="text-muted font-semibold">
                                {" "}
                                <i className="fa fa-clock-o"></i> Personal
                                Caller(in Month)
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                
                <div className="card">
                  <div className="card-header text-center">
                    <h5>
                      <u>Calls Duration (in seconds)</u>
                    </h5>
                  </div>
                  <div className="card-body text-center">

                  { hourlyCallLoading ? 
                  <Skeleton height={"43vh"} /> :
                   <Chart
                      series={series_chart}
                      options={{
                        chart: {
                          height: 350,
                          type: "line",
                          zoom: {
                            enabled: false,
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        stroke: {
                          curve: "straight",
                        },
                        xaxis: {
                          categories: hours_chart,
                        },
                        colors: ["#53b1defc", "#74f29b", "#2728289c"],
                        grid: {
                          row: {
                            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                            opacity: 0.5,
                          },
                        },
                      }}
                      type="line"
                      width={600}
                      height={300}
                      padding={0}
                    ></Chart>}
                  </div>
                </div>

              
              </div>

              <div className="col-lg-6">
              <div className="card">
                  <div className="card-header text-center">
                    <h5>
                      <u>Call Log Activity(in months)</u>
                    </h5>
                  </div>
                  <div className="card-body text-center">
                  { monthlyCallLoading ? 
                  <Skeleton height={"43vh"} /> :
                   <Chart
                      series={log_series}
                      options={{
                        chart: {
                          type: "bar",
                          height: 350,
                        },
                        plotOptions: {
                          bar: {
                            horizontal: false,
                            columnWidth: "55%",
                            endingShape: "rounded",
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        stroke: {
                          show: true,
                          width: 2,
                          colors: ["transparent"],
                        },

                        xaxis: {
                          categories: logs_month,
                        },
                        yaxis: {
                          title: {
                            text: "$ of Calls",
                          },
                        },
                        fill: {
                          opacity: 1,
                        },
                        colors: ["#3255c5", "#059929"],
                        tooltip: {
                          y: {
                            formatter: function (val) {
                              return val;
                            },
                          },
                        },
                      }}
                      type="bar"
                      width={600}
                      height={300}
                      padding={0}
                    ></Chart>}
                  </div>
                </div>
              </div>


              <div className="col-lg-6">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ borderBottom: "1px solid" }}
                  >
                    <h6>
                      Daily Activity (
                      {dailyactivityData && dailyactivityData.total_data})
                    </h6>
                  </div>
                  <div className="card-body mt-3">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th className="order">Agent Name</th>
                            <th className="order">Agent Code</th>
                            <th className="order">Incoming Call</th>
                            <th className="order">Outgoing Call</th>
                            <th className="order">Incoming Duration</th>
                            <th className="order">Outgoing Duration</th>
                            <th className="order">Missed Call</th>
                            <th className="order">Not Pickup</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dailyActivityLoading ? 
                          <TableSkeleton count={6} /> :
                            currentRecords1?.map((item, index) => (
                              <tr>
                                <td>{item.agent_name}</td>
                                <td>{item.agent_code}</td>
                                <td>{item.total_incoming_call}</td>
                                <td>{item.total_outgoing_call}</td>
                                <td>{item.incoming_duration}</td>
                                <td>{item.outgoing_duration}</td>
                                <td>{item.total_missed_call}</td>
                                <td>{item.total_notpickup}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    <div style={{ textAlign: "right", float: "right", marginTop:"10px" }}>
                      <Paginationcustom
                        data={nPages1}
                        changepage={changepage1}
                        currentPage={activitypage}
                        setCurrentPage={setActivitypage}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <h6>Device Permission</h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        { userDeviceLoading ? <TableSkeleton count={1} /> :
                        currentRecords?.map((a, v) => (
                          <>
                            <thead>
                              <tr className="custom_bg">
                                <th colSpan="2">
                                  <span>Employee - ({a?.user?.name}-
                                    {a?.user?.user_unique_code})</span> <br />
                                  <span>Device -{" "}
                                  {a.device_information != null
                                    ? a?.device_information?.brand
                                    : null}{" "}{a?.device_information?.model ? `(${a?.device_information?.model})` : null}</span> ,{" "}
                                      <span>Android Version - {a?.device_information?.android_version}</span> ,
                                    <br/>
                                    {a?.device_information?.recorder_app_version ? <span>Recorder App Version : {a?.device_information?.recorder_app_version} ,</span> : null }  
                                    {a?.device_information?.silent_app_version ? <span>Silent App Version : {a?.device_information?.silent_app_version} ,</span> : null }  
                                  Device Id- {a?.device_id}
                                  <br />
                                  Last Update - {a?.last_updated_at}
                                </th>
                              </tr>

                              <tr>
                                <th>Permission</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {devicepermission.map((item, index) => (
                                <>
                                  <tr>
                                    <td>{item.permission_name}</td>
                                    <td>
                                      {a.device_permission != null ? (
                                        a.device_permission
                                          .split(",")
                                          .some((c) => item.id == c) == true ? (
                                          <span className="badge bg-light-success">
                                            True
                                          </span>
                                        ) : (
                                          <span className="badge bg-light-danger">
                                            False
                                          </span>
                                        )
                                      ) : (
                                        <span className="badge bg-light-danger">
                                          False
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </>
                        ))}
                      </table>
                    </div>

                    <div style={{ textAlign: "right", float: "right" }}>
                      <Paginationcustom
                        data={nPages}
                        changepage={changepage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
